.form{
	//background: lighten($primary,0.9);
	textarea.form-control{
		min-height: calc(1.5em + 1rem + 2px);
	}
}

$logoHeight: 3rem;
$responsive-border: false;

.login{
	display: flex;
	flex-direction: column;
	justify-content: space-evenly;
	align-items: center;
	@include media-breakpoint-down(sm){
		@include media-landscape{
			flex-direction: row;
			align-items: center;
			> *{
				margin: 0;
				//height: 100%;
				display: inline-flex;
				flex-direction: column;
				justify-content: center;
			}
		}
	}
	height: 100%;

	> .login-card{
		margin: auto;
		font-size: 0.8em;
		background: $secondary;
		border: 2px solid $primary;
		box-shadow: $box-shadow;
		padding: $logoHeight;
		position: relative;
		border-radius: $border-radius-lg;
		color: $primary;
		@if ($responsive-border){
			@include media-breakpoint-down(sm){
				background: $primary;
				padding: 2rem 2rem 1rem;
				border-radius: 0;
			}
		}

		.login-link{
			color: $primary !important;
			@if $emphasized-link-hover-darken-percentage != 0{
				&a{
					@include hover-focus(){
						color: darken($primary, $emphasized-link-hover-darken-percentage) !important;
					}
				}
			}
			@include media-breakpoint-down(sm){
				color: $secondary !important;
				@if $emphasized-link-hover-darken-percentage != 0{
					&a{
						@include hover-focus(){
							color: darken($secondary, $emphasized-link-hover-darken-percentage) !important;
						}
					}
				}
			}
		}

		> .form-group{
			background: $primary;
			padding: 1rem;
			color: $light;
			border-radius: inherit;
			margin-bottom: 0;
		}

		.login-title{
			height: $logoHeight;
			order: 10;
			font-size: 1rem;
			position: absolute;
			top: 0;
			left: 0;
			padding: 0.5rem;
			border: none;
			box-shadow: none;
			text-align: right;
			margin: 0;
			display: flex;
			justify-content: space-between;
			align-items: flex-end;

			@if ($responsive-border){
				@include media-breakpoint-down(sm){
					display: none;
				}
			}

			> img{
				filter: invert(1);
				vertical-align: bottom;
				margin-right: 0.3em;
			}

			~ .login-title{
				top: unset;
				left: unset;
				right: 0;
				bottom: 0;
				transform: rotate(180deg);
			}
		}

		.login-logo{
			width: calc(100% - #{$logoHeight});
			height: 100%;
			background: $primary;
		}
	}

	#main{
		height: 100%;
		margin: 0;
	}

	input{
		font-size: 1.4em;
		box-shadow: 4px 4px 5px rgba(0, 0, 0, 0.5);
		@include hover-focus{
			box-shadow: 4px 4px 5px $input-focus-color;
		}
	}

	button[type="submit"]{
		/* Azul */
		background: darken($primary, 20%);
		color: $light;
		/* Amarelo */
		border: 6px solid $light;
		box-sizing: border-box;
		box-shadow: 4px 4px 5px rgba(0, 0, 0, 0.5);
		border-radius: 10px;

		&:active,
		&:focus,
		&:hover{
			&:not(.disabled):not([disabled]){
				border-color: $secondary;
				color: $secondary;
			}
		}
	}

	.avatar{
		/* degrade azul_amarelo */
		background: linear-gradient(180deg, #0f2144 0%, #eed793 100%);
		border-radius: 100%;
		width: 25vh;
		height: 25vh;
		display: flex;
		justify-content: center;
		align-items: center;

		> svg,
		> img{
			border-radius: 100%;
			width: 100%;
			height: 100%;
		}
	}
}