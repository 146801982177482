$avatarDistance: 1em;
$avatarSize: 4em;
$fontSize: 0.8rem;
$actionHeight: 1.5rem;
$effectBorder:4px dotted $primary;

.forum{
	display: flex;
	flex-direction: column;
	position: relative;
	width: 100%;
	padding-left: $avatarDistance/2;
	> .forum-replies{
		position: relative;
	}
}

.forum-reply{
	display: flex;
	flex-direction: column;
	width: 100%;
	position: relative;
	padding: $avatarDistance $avatarDistance 0;
	font-size: $fontSize;
	margin-top: $avatarDistance;
	&.forum-reply-right{
		> .forum-reply-content{
			margin-right: 0;

			> .forum-reply-meta{
				flex-direction: row-reverse;
				right: -$avatarDistance/2;
				left: unset;
			}
		}
	}

	> .forum-reply-content{
		&.form-group{padding: 0 !important;}
		background: $white;
		//border: $menuBorder;
		&:not(.form){
			padding: $avatarDistance $avatarDistance*2;

			.forum-reply-button{
				float: right;
			}
		}

		border-radius: 20px;
		$marginRight: $avatarDistance * 4;
		&.form-group{margin-right: 0;}


		> .forum-reply-input > .form-control,
		> .forum-reply-text{
			text-indent: 1em;
		}

		> .forum-reply-input{
			display: flex;
			>textarea{
				max-height: 10em;
			}

			> [type="submit"]{
				//margin: 2px;
			}

			> :not(:only-child){
				&:last-child{
					border-top-left-radius: 0px;
					border-bottom-left-radius: 0px;
					border-top-right-radius: 20px;
					border-bottom-right-radius: 20px;
				}

				&:first-child{
					border-top-right-radius: 0px;
					border-bottom-right-radius: 0px;
					border-top-left-radius: 20px;
					border-bottom-left-radius: 20px;
				}
			}

		}

		> .forum-reply-meta{
			display: flex;
			flex-direction: row;
			float: left;
			position: absolute;
			left: -$avatarDistance/2;
			top: -$avatarDistance/1.5;
			margin-top: 0.4em;

			> .forum-reply-avatar,
			.forum-reply-user{
				font-size: $fontSize;
			}

			.forum-reply-avatar{
				width: $avatarSize;
				height: $avatarSize;
				//margin: 0 0.5em;
				background: fade_out($light,0.1);
				padding: 0.3em;
				border-radius: 100%;
				&.forum-reply-avatar-styled{
					border: 3px solid $light;
					background: fade_out($dark,0.1);
				}
				img{
					width: 100%;
					height: auto;
					max-height: 100%;
					max-width: 100%;
					border-radius: 100%;
				}
			}
		}
	}

	> .forum-reply-action{
		display: flex;
		justify-content: space-between;
		padding: 0 1rem;
		height: $actionHeight;
		> .btn{
			margin-right: auto;
			padding-left: 1.3em !important;
		}

		.btn{
			font-size: 0.8em;
			padding: 0em 1em;
			border-radius: 0;
			min-width: $avatarSize;

			.meta{
				position: relative;
				top: 1px;
			}

			i + .meta{
				margin-left: map_get($spacers,2);
			}

			.meta:first-child{
				margin-right: map_get($spacers,2);
			}

			&:last-child{
				border-bottom-right-radius: 20px;
				padding-right: 1.5em;
			}

			&:first-child{
				border-bottom-left-radius: 20px;
				padding-left: 1.5em;
			}
		}
	}
}