@import "assets/variables";

@import "assets/bootstrap/theme";
@import "assets/icons/css/school.css";
@import "~slick-carousel/slick/slick.css";
@import "~slick-carousel/slick/slick-theme.css";
@import "~sweetalert2/src/sweetalert2";

@import "assets/form/form";
@import "assets/school-loader";
@import "assets/widgets/widgets";

//@import "assets/components/footer";
@import "assets/components/new/content";
@import "assets/components/new/header";
@import "assets/components/new/pages";
//@import "assets/components/sidebar";
//@import "assets/components/content";
//@import "assets/components/mobile";
//@import "assets/components/video";
//@import "assets/components/file";
//@import "assets/components/forum";
//@import "assets/components/tips";
//@import "assets/components/learning_object";
@import "~jsoneditor-react/es/editor.css";

@import "assets/table";
@import "assets/alert";
@import "assets/buttons";

body,
#root{
	font-family: "Roboto", sans-serif;
}

@each $color, $value in $theme-colors{
	@include bg-variant(".bg-alert-#{$color}", theme-color-level($color, $alert-bg-level));
	.text-shadow-#{$color}{
		text-shadow: 2px 0 0 $value, -2px 0 0 $value, 0 2px 0 $value, 0 -2px 0 $value, 1px 1px $value, -1px -1px 0 $value, 1px -1px 0 $value, -1px 1px 0 $value;
	}
}


@each $color, $background in $theme-colors{
	img.text-#{$color}{
		filter: contrast(55%) brightness(200%) sepia(100%) hue-rotate(hue($background)-30deg);
	}
	svg.text-#{$color}{
		fill: $background;
	}
	$hover-background: darken($background, 7.5%);
	$active-background: darken($background, 10%);
	.btn-#{$color} > image{
		filter: contrast(50%) brightness(100%) sepia(100%) hue-rotate(hue(color-yiq($background))-30deg);

		@include hover(){
			filter: contrast(50%) brightness(100%) sepia(100%) hue-rotate(hue(color-yiq($hover-background)));
		}

		&:focus,
		&.focus{
			filter: contrast(50%) brightness(100%) sepia(100%) hue-rotate(hue(color-yiq($hover-background)));
		}

		// Disabled comes first so active can properly restyle
		&.disabled,
		&:disabled{
			filter: contrast(50%) brightness(100%) sepia(100%) hue-rotate(hue(color-yiq($background)));
		}

		&:not(:disabled):not(.disabled):active,
		&:not(:disabled):not(.disabled).active,
		.show > &.dropdown-toggle{
			filter: contrast(50%) brightness(100%) sepia(100%) hue-rotate(hue(color-yiq($active-background)));
		}
	}
	.btn-#{$color} > svg{
		//padding: 6px;
		fill: color-yiq($background);

		@include hover(){
			fill: color-yiq($hover-background);
		}

		&:focus,
		&.focus{
			fill: color-yiq($hover-background);
		}

		// Disabled comes first so active can properly restyle
		&.disabled,
		&:disabled{
			fill: color-yiq($background);
		}

		&:not(:disabled):not(.disabled):active,
		&:not(:disabled):not(.disabled).active,
		.show > &.dropdown-toggle{
			fill: color-yiq($active-background);
		}
	}
}

@for $i from 1 through 20{
	.o-#{$i*5}{
		opacity: $i*5/100;
	}
}

@for $i from 1 through 100{
	.w-#{$i*5}px{
		width: #{$i*5}px;
	}
	.h-#{$i*5}px{
		height: #{$i*5}px;
	}
	.square-#{$i*5}px{
		height: #{$i*5}px;
		width: #{$i*5}px;
	}
}

@each $size, $length in $spacers{
	.w-#{$size}{
		width: #{$length*2};
	}
	.h-#{$size}{
		height: #{$length*2};
	}
	.square-#{$size}{
		height: #{$length*2};
		width: #{$length*2};
	}
}

.top-0{
	top: 0;
}

.bottom-0{
	bottom: 0;
}

.left-0{
	left: 0;
}

.right-0{
	right: 0;
}

.rounded-top-0{
	@include border-top-radius(0)
}

.rounded-bottom-0{
	@include border-bottom-radius(0)
}

.rounded-left-0{
	@include border-left-radius(0)
}

.rounded-right-0{
	@include border-right-radius(0)
}

.btn-left{
	position: absolute;
	top: 0;
	bottom: 0;
	left: 0;
	width: map_get($spacers,3)*2;
	display: flex;
	align-items: center;
	@include border-right-radius(0)
}

.btn-right{
	position: absolute;
	top: 0;
	bottom: 0;
	right: 0;
	width: map_get($spacers,3)*2;
	display: flex;
	align-items: center;
	@include border-left-radius(0)
}

body,
main,
#root{
	overflow: hidden;
	height: 100%;
}

//tbody > tr:first-child td{
//	border-top: none;
//}

#profile{
	@extend .container;
	$profilePadding: map_get($spacers, 5);
	padding: $profilePadding;
	background: fade_out($white, 0.6);
	position: relative;

	.profile-actions{
		margin-top: -$profilePadding;
		margin-right: -$profilePadding;
		margin-left: -$profilePadding;
		margin-bottom: $profilePadding/2;
		display: flex;
		justify-content: space-between;

		&.profile-actions-left{
			justify-content: flex-start;
		}
	}
}

.info-bar{
	position: absolute;
	top: 0;
	right: 0;
	text-transform: uppercase;
	display: flex;
	justify-content: flex-end;
	align-items: flex-end;
	padding: map_get($spacers, 1);
	height: $infobarHeight;
	overflow: hidden;
	z-index: 5;

	> .btn{
		//width: $infobarHeight - 0.5rem;
		//height: $infobarHeight - 0.5rem;
	}
}

.btn{
	&.disabled,
	&[disabled]{
		position: relative;
		cursor: not-allowed;

		&.alternative{
			cursor: default;
		}

		&:focus::after{
			content: " ";
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			background: inherit;
			height: 100%;
			z-index: -1;
		}
	}

	&.close-button{
		position: absolute;
		top: 0;
		right: 0;
		border-radius: 0;
	}
}

.cursor-default{
	cursor: default !important;
}

.none-events{
	pointer-events: none;
}

body{
	@include bodyBackground();
	background-image: url("~assets/images/bg2.png");
	overflow-x: hidden;
}

.w-0{
	width: 0 !important;
}

.icon:before{
	color: inherit !important;
}

#root{
	position: relative;
}
.react-select__menu{
	z-index: 100;
}
.react-select__multi-value__label .react-select__value-container{
	padding: 0 !important;
	height: 1.3em;
}
.react-select.form-control{
	padding: 0;
	border: none;
	height: auto;
	border-radius: 10px;
	> .react-select__control{
		&,
		> .react-select__indicators{
			height: calc(1.5em + 0.75rem + 2px);
		}
		font-size: 1rem;
		.react-select__value-container{
			//@extend .form-control;
		}
	}
	&.form-control-lg > .react-select__control{
		&,
		> .react-select__indicators{
			height: calc(1.5em + 1rem + 2px);
		}
		font-size: 1.25rem;

		.react-select__value-container{
			@extend .form-control-lg;
		}
	}
}

.input-group{
	> .input-group-append{
		> .input-group-text{
			//@include border-left-radius($border-radius)
		}
	}

	> .react-select.form-control{
		&:first-child{
			> .react-select__control{
				@include border-right-radius(0);
			}
		}

		&:not(:first-child){
			> .react-select__control{
				@include border-left-radius(0);
			}
		}
	}
}

.react-select__option--is-selected{
	svg{
		fill: white;
	}
}

$primary25: rgba($primary, 0.25);
$primary50: rgba($primary, 0.5);
$primary75: rgba($primary, 0.75);
&:root,
:root{
	--primary: #{$primary};
	--primary25: #{$primary25};
	--primary50: #{$primary50};
	--primary75: #{$primary75};
}


table > tbody:first-child tr:first-child td{
	border-top: none;
}

.header-logo{
	max-height: $headerHeight;
}

.custom-control{
	z-index: auto;
}

.custom-file-label{
	color: $input-placeholder-color;
}


$dark-light:          							    $gray-400 !default; // Custom variable
$primary-light:    									lighten(desaturate($primary,33%),23%) !default; // Custom variable
$secondary-light:  									lighten(desaturate($secondary,33%),23%) !default; // Custom variable
$success-light:    									lighten(desaturate($success,33%),23%) !default; // Custom variable
$info-light:   		 								lighten(desaturate($info,33%),23%) !default; // Custom variable
$warning-light:    									lighten(desaturate($warning,33%),23%) !default; // Custom variable
$danger-light:     									lighten(desaturate($danger,33%),23%) !default; // Custom variable
$white-light:    									$white !default;  // Custom variable
$light-light:         								$gray-100 !default
$theme-light-colors: (
		"white":      $white-light,
		"primary":    $primary-light,
		"secondary":  $secondary-light,
		"success":    $success-light,
		"info":       $info-light,
		"warning":    $warning-light,
		"danger":     $danger-light,
		"light":      $light-light,
		"dark":       $dark-light
);

@each $name, $color in $theme-light-colors {
	// Light colors
	.text-light-#{$name} {
		color: $color !important;
	}
}
