
.video{
	position: relative;
	overflow: hidden;
	user-select: none;
	$sizeControl: 4rem;
	$sizeControlProgress: $sizeControl / 3;
	//margin-bottom: $footerHeight;
	//padding-top: calc(#{100 / (16/9) + "%"} - #{$footerHeight} - #{$headerHeight} - 4rem);
	//padding-top: calc(#{100vh / (16/9)} + #{$headerHeight} - #{$footerHeight});
	transition: padding-top 1s;
	//height: calc(100vh - #{$headerHeight} - #{$footerHeight});

	@include media-breakpoint-down(sm){

		@include media-landscape{
			height: calc(100vh - #{$headerHeight} - #{$footerHeight});
			//@at-root #{selector_nest(".show-footer",&)}{
			//height: calc(80vh - #{$headerHeight} - 1rem - #{$footerHeight});
			//}
			//padding-top: calc(#{80 / (16/9) + "%"} - #{$footerHeight});
		}
	}


	&.hide-controls{
		padding-bottom: 0;
	}

	//z-index: 90;
	//height: calc(100% - #{$sizeControl});
	//width: 100%;
	//min-height: 60vh;
	@media (max-device-height: 60vh){
		//min-height: 100%;
	}
	@include media-breakpoint-down(sm){
		//height: 100%;
		@include media-landscape{
			padding-left: $sizeControl;
			padding-right: $sizeControl;
			//padding-bottom: $footerHeight;
			> .info-bar{
				margin-right: $sizeControl;
				opacity: 0.1;
				z-index: 61;
				transition: opacity 0.3s;

				&:hover{
					opacity: 1;
				}
			}
		}
	}

	.video-element-container{
		margin: 0 auto;
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		position: relative;
		max-height: 100%;
		//width: 100%;
		//height: 100%;
		//left: 0;
		//right: 0;
		//top: 0;
		//bottom: 0;
		//@include media-breakpoint-down(sm){
		//	@at-root #{selector_nest(".hidden-sidebar ~ #main",&)}{
		//		bottom: $footerHeight;
		//	}
		//}
	}

	.video-element{
		//background: $dark;
		//position: absolute;
		//left: 0;
		//right: 0;
		//top: 0;
		//bottom: 0;
		//height: calc(100% - #{$sizeControl}) !important;
		//width: 100% !important;
		overflow: hidden;
		transition: height 1s;

		> *{
			pointer-events: none;
		}

		@include media-breakpoint-down(sm){
			@include media-landscape{
				//left: $sizeControl;
				//right: $sizeControl;
				//width: calc(100% - #{$sizeControl*2}) !important;
				//height: 100% !important;
			}
		}
		//@at-root #{selector_append(".video-fullscreen",&)}{
		//bottom: 0 !important;
		//}
		//@at-root #{selector_nest(".hidden-sidebar ~ #main",&)}{
		//height: calc(100% - #{$footerHeight}) !important;
		//}
		//@include media-breakpoint-down(sm){
		//height: 100% !important;
		//}
		iframe,
		video{
			height: 100%;
			width: 100%;
			//max-height: 100%;
		}

		&.video-element-loader{
			position: absolute;
			left: 0 !important;
			right: 0 !important;
			top: 0 !important;
			bottom: 0 !important;
			background: $dark;
			transition: opacity 1s;
			margin: 0 auto;
			@include border-bottom-radius(20px);
			//opacity: 1;
			//&.video-element-loaded{
			//	opacity: 0;
			//}

			> .loader{
				//padding: $footerHeight/2;
				border: 2px solid $primary;
				height: 100%;
				border-radius: inherit;
				z-index: 149;
				min-height: auto;
				> .loader-content{
					margin-bottom: 0;
				}
			}
		}
	}
	.video-redirect-message{
		//position: absolute;
		//bottom: 0;
		//right: 0;
		width: 100%;
		//bottom: $sizeControl + $sizeControlProgress;
	}
	.video-control-progress{
		position: relative;
		//left: 0;
		//right: 0;
		//bottom: $sizeControl - 0.5rem;
		height: $sizeControlProgress;
		margin-top: -$sizeControlProgress;

		> .progress{
			position: absolute;
			top: 0;
			left: 0;
			right: 0;
			bottom: 0;
			border-radius: 0;
			height: 100%;
			pointer-events: none;
			z-index: 70;
			border: 1px solid fade_out($primary, 0.8);
			opacity: 0.05;
			transition: opacity 0.3s;
		}

		> .video-time{
			position: absolute;
			right: 0;
			bottom: 0;
			transition: bottom 0.6s;
			color: $light;
			padding: 0 1rem;
			display: flex;
			flex-direction: column;
			> .video-timer{
				align-self: flex-end;
				font-size: 1.5em;
			}
		}

		&:hover{
			> .progress{
				opacity: 0.8;
			}

			> .video-time{
				bottom: $sizeControlProgress;
			}
		}

		z-index: 60;
		//@at-root #{selector_append(".video-fullscreen",&)}{
		//bottom: 0 !important;
		//height: $sizeControl /2;
		//}
		//overflow: hidden;
		@include media-landscape{
			@include media-breakpoint-down(sm){
				bottom: 0;
			}
		}
		//@include media-breakpoint-down(sm){
		//@at-root #{selector_nest(".hidden-sidebar ~ #main",&)}{
		//bottom: $footerHeight;
		//}
		//}

		> input{
			opacity: 0;
			width: 100%;
			height: 100%;
		}
	}

	.video-controls{
		//position: absolute;
		//left: 0;
		//right: 0;
		bottom: 0;
		z-index: 60;
		height: $sizeControl;
		transition: bottom 0.3s, width 0.3s, height 0.3s;
		background: $light;
		border: 1px solid fade_out($primary, 0.8);
		@include border-bottom-radius(20px);
		overflow: hidden;

		@include media-landscape{
			@include media-breakpoint-down(sm){
				&:not(.video-controls-landscape-mobile){
					display: none;
				}
			}
		}

		&.video-controls-landscape-mobile{
			position: absolute;
			left: 0;
			right: 0;
			bottom: 0;
			top: 0;
			display: none;
			border-radius: 0;
			flex-direction: column;
			height: 100%;
			width: $sizeControl;
			//@at-root #{selector_nest(".hidden-sidebar ~ #main",&)}{
			//height: calc(100% - #{$footerHeight});
			//}
			@include media-landscape{
				@include media-breakpoint-down(sm){
					display: flex;
					align-items: flex-end;
					justify-content: flex-end;
					+ .video-controls-landscape-mobile{
						right: 0;
						left: unset;
					}
					> .video-control-backward,
					> .video-control-forward{

					}
				}
			}
		}

		> *{
			height: 100%;
			@include media-landscape{
				@include media-breakpoint-down(sm){
					height: auto;
					width: 100%;
				}
			}
			> * > :first-child{
				font-size: 1.5em;
			}

			&:not(:first-child){
				border-left: 1px solid fade_out($primary, 0.8);
			}

			box-shadow: none;
			display: flex;
			align-items: center;
			justify-content: center;
		}

		> .video-control-play{
			width: 100%;
			min-width: $sizeControl;
			min-height: $sizeControl;
			flex: 1 1;
		}

		> .video-control-forward,
		> .video-control-backward{
			@include media-breakpoint-down(sm){
				@include media-portrait{
					display: none;
				}
			}
		}

		> .video-control-forward,
		> .video-control-backward,
		> .video-control-speed,
		> .video-control-mute,
		> .video-control-fullscreen,
		> .video-control-extra{
			width: $sizeControl;
			//height: $sizeControl;
			min-width: $sizeControl;
			//min-height: $sizeControl;
			flex: 1 1;
		}
	}

	.container-video-main-play{
		position: absolute;
		top: 0;
		bottom: 0;
		z-index: 60;
		display: flex;
		justify-content: center;
		align-items: center;
		////margin: 0 auto;

		.video-main-play{
			> .fa-play{
				margin-left: 0.2em;
			}
			width: 1em;
			height: 1em;
			font-size: 1.2em;
			border-radius: 100%;
			display: flex;
			justify-content: center;
			align-items: center;
			padding: 2em;
			//border: 2px dashed $primary;
			color: $light;
		}
	}
}

.hide-controls .video{
	.video-controls{
		display: none;
	}

	.video-element{
		pointer-events: auto;
	}

	//height: 100% !important;
}

.video-playing .video .video-control-progress{
	//background: red;
	&:hover{
		//background: blue;
		transition: opacity 1s;
		opacity: 0.8;
	}
}

.intro-container{
	position: fixed;
	left: 0;
	right: 0;
	top: 0;
	bottom: 0;
	display: flex;
	justify-content: center;
	align-items: center;
	height: 100%;

	.video{
		width: 100%;
		height: 100%;
		max-height: 100%;
		max-width: 100%;
	}
	.video-element,
	.loader,
	.video-element-container,
	.video-controls,
	.video-controls > *{
		border-radius: 0 !important;
		border: none !important;
	}
}

.video-fullscreen{
	.video-element-container{
		.container-video-main-play,
		.video-element{
			width: 100% !important;
			height: 100vh !important;
		}

		.video-controls,
		.video-control-progress{
			width: 100% !important;
			border-radius: 0;
		}
	}
}