@import url("https://fonts.googleapis.com/css?family=Roboto:100,300,400,500,700");
@import "~@fortawesome/fontawesome-free/scss/solid";
@import "~@fortawesome/fontawesome-free/scss/brands";
@import "~@fortawesome/fontawesome-free/scss/regular";
@import "~@fortawesome/fontawesome-free/scss/fontawesome";
@import "~perfect-scrollbar/css/perfect-scrollbar.css";


html{
	@import "assets/variables";
	@import "~bootstrap/scss/grid";
	height: 100%;
}

// Document
//
// 1. Change from `box-sizing: content-box` so that `width` is not affected by `padding` or `border`.
// 2. Change the default font family in all browsers.
// 3. Correct the line height in all browsers.
// 4. Prevent adjustments of font size after orientation changes in IE on Windows Phone and in iOS.
// 5. Change the default tap highlight to be completely transparent in iOS.

*,
*::before,
*::after{
	box-sizing: border-box; // 1
}

[data-color=""]{
	height: 100%;
	$primary: #009eb8;
	$secondary: adjust_hue(lighten(#009eb8, 50%),20deg);
	$info: #9761ba;
	$danger: #f98865;
	$warning: #daaa49;
	$success: #0071bc;

	@import "assets/template";

	#{selector_append("html",&)}{
		-webkit-tap-highlight-color: rgba($black, 0); // 5
	}
}

[data-color="pokermindschool"],
[data-color="poker-mind-school"]{
	$primary: #0f2144;
	$secondary: #eed793;
	@import "assets/template";

	#{selector_append("html",&)}{
		-webkit-tap-highlight-color: rgba($black, 0); // 5
	}
}
