@import "assets/components/new/content/widget/widget";

@mixin sidebar{
	padding-top: calc(#{$headerHeight} + 1em);
	@include media-breakpoint-down(sm){
		padding-top: 1em;
	}
	right: -$rightSidebarWidth;
	display: flex;
	align-items: flex-end;
	flex-direction: column;
	text-align: right;
	.widget-tabs,
	.badges-section{
		color: $light;
	}
}

#sidebar-right{
	overflow-y: auto;
	height: calc(100% - #{$headerHeight});
	width: $rightSidebarWidth;
	position: fixed;
	padding: 1rem;
	background-color: rgba(96, 96, 96, 0.54);
	color: $primary;
	right: 0;
	transition: right $time*3 ease, left $time, width 1.2s, background-color 1.2s;
	z-index: 180;
	@include media-breakpoint-down(md){
		@include sidebar;
	}
	@include media-breakpoint-down(sm){
		right: -100%;
	}
}

.active-sidebar #sidebar-right{
	@include media-breakpoint-down(md){
		width: 50%;
		right: 0;
		background-color: rgba(25, 25, 25, 0.9);
		color: $light;
	}
	@include media-breakpoint-down(sm){
		width: 100%;
	}
}

.show-learning-object #sidebar-right{
	@include sidebar;
}

.active-sidebar.show-learning-object #sidebar-right{
	width: $rightSidebarWidth;
	background-color: rgba(25, 25, 25, 0.9);
	color: $light;
	right: 0;
	@include media-breakpoint-down(md){
		//width: 50%;
	}
	@include media-breakpoint-down(sm){
		width: 100%;
	}
}