@import "assets/components/new/content/footer/forum";
@import "assets/components/new/content/footer/range";
@import "assets/components/new/content/footer/file";
@import "assets/components/new/content/footer/tips";


$widgets: (
		"comments",
		"glossaries",
		"tips",
		"files",
		"ranges",
		"faq",
);

#main-footer{
	margin-left: $leftSidebarWidth;
	margin-right: $rightSidebarWidth;
	padding-top: 0;
	padding-left: 0;
	overflow: hidden;
	position: relative;
	left: -100%;
	//display: none;
	z-index: 90;
	//width: 0;
	max-height: 0;
	transition: left $time*2 ease, max-height $time ease, padding $time;

	> [data-widget]{
		width: 0;
		max-height: 0;
		position: relative;
		left: -100%;
		transition: left $time ease, right $time ease, width $time ease, max-height $time ease;
	}

	&[data-widget-active-last]:not([data-widget-active]) > [data-widget]{

	}

	@each $widget in $widgets{
		&:not([data-widget-active]) > [data-widget="#{$widget}"]{
			transition: left $time ease, right $time ease, width $time ease, max-height $time ease;
		}
		//> [data-widget="#{$widget}"]{
		//	background: blue;
		//	transition: left $time ease, width $time ease;
		//}
		&[data-widget-active="#{$widget}"] > :not([data-widget="#{$widget}"]){
			transition: all 0s;
		}
		&[data-widget-active-last="#{$widget}"]:not([data-widget-active]),
		&[data-widget-active="#{$widget}"]{
			> [data-widget="#{$widget}"]{
				left: 0;
				width: 100%;
				max-height: 100%;
			}
		}
	}
}

.show-footer #main-footer{
	padding: 1rem 1rem 2rem;
	@include media-landscape{
		//padding-top: 15px;
		@include media-breakpoint-down(sm){
			margin-top: -1.5em;
		}
	}

	&[data-widget-active="ranges"]{
		padding-left: 0;

	}

	&[data-widget-active="comments"]{
		padding-right: 0;
	}

	//margin-bottom: 1rem;
	transition: left $time*2 ease;
	left: 0;
	//height: auto;
	max-height: inherit;
	//max-height: 100%;
	width: calc(100% - #{$leftSidebarWidth} - #{$rightSidebarWidth});
	@include media-breakpoint-down(md){
		@include hideSidebarRight;
	}
	@include media-breakpoint-down(sm){
		@include hideSidebarBooth;
		margin-bottom: calc(1rem + #{$footerHeight});
	}
}

.hide-sidebar-right #main-footer{
	@include hideSidebarRight;
}

.hide-sidebar-right.hide-sidebar-left #main-footer{
	@include hideSidebarBooth;
}

.show-range #main-footer{
	//display: none;
	@include media-breakpoint-down(sm){
		//display: inline-flex;
		padding-left: 0;
		padding-right: 0;
		margin: 0;
	}
}