@import "assets/variables";

.tips{
	color: $primary;

	.tip-list{
		display: flex;
		flex-direction: column;

		.tip-entry{
			padding: 0.3em;
			margin-bottom: 0.3em;
			border-bottom: 1px solid fade_out($primary, 0.9);

			> .tip-header{
				font-size: 1.4em;
				line-height: 1.1em;
				font-weight: 600;
			}

			> .tip-body{
				font-weight: 300;
			}
		}
	}
}