@import "node_modules/bootstrap/scss/mixins";
@import "node_modules/bootstrap/scss/functions";
@import "node_modules/bootstrap/scss/variables";

$theme-colors: map_merge($theme-colors,(
	"discord": #7289DA,
	"whatsapp": #00E676,
));
$input-border-radius: 10px;
$input-bg: #ffffff;
//$input-color: $primary;
//$input-placeholder-color: fade_out(saturate($primary, 30%),0.7);

$enable-shadows: true;
$custom-file-text: (
		en: "Selecionar",
	//en: "Browse",
	//	es: "Elegir"
);
$ignore-warning: true;


$custom-file-border-radius: $input-border-radius-lg;
$custom-file-color: $input-placeholder-color;
$breadcrumb-bg: $primary;
$breadcrumb-active-color: $secondary;

$headerHeight: 3.5rem;
$breadcrumbHeight: 3rem;
$footerHeight: 2rem;
$leftSidebarWidth: 5rem;
$infobarHeight: 2.5rem;
$lefSidebarMinHeight: $leftSidebarWidth * 6;
$rightSidebarWidth: 18rem;
$rightSidebarActionsHeight: 2.5rem;
$menuBorderSize: 1px;
$menuBorderColor: $gray-200;
$menuBorder: $menuBorderSize solid $menuBorderColor;
$breakpointSchool: md;
$time: 0.6s;

@mixin bodyBackground(){
	background-position: center;
	background-repeat: no-repeat;
	background-size: cover;
	background-attachment: fixed;
}

@mixin media-portrait(){
	@media all and(orientation: portrait){
		@content;
	}
}
@mixin media-landscape(){
	@media all and(orientation: landscape){
		@content;
	}
}