$moduleCardBorderRadius: 20px;
$moduleCardPaddingY: 10rem;
$backdropSize: 6rem;
$moduleCardPaddingX: $moduleCardPaddingY / 6;
$moduleCardShadow: 0px 5px 5px rgba(0, 0, 0, 0.25);
$moduleWidth: 300px;
$moduleHeight: 450px;

#content.module-listing{
	//display: flex;
	//justify-content: center;
	//align-items: center;

	> #main{
		max-height: 100%;
		height: 100%;
		//display: flex;
		//align-items: center;
	}

	.slick-slider{
		height: 100%;
		//height: calc(100% - 25px);
	}
	.slick-dots{
		bottom: 0;
	}
	.slick-list,
	.slick-track{
		height: 100%;
	}
	.slick-track > *{
		display: flex;
		justify-content: center;
		align-items: center;
	}
	.slick-slide {
		display: flex;
		justify-content: center;
		> *{
			height: 100%;
			display: flex;
			justify-content: center;
			align-items: center;
		}
	}
}

.module-card-container{
	border-radius: $moduleCardBorderRadius;
	background-color: $light;
	padding: $moduleCardBorderRadius;
	margin-right: 1rem;
	background: radial-gradient(#FFFFFF 70%, #C4C4C4 100%);
	box-shadow: $box-shadow;
	border: 1px solid fade_out($primary, .8);
	max-width: $moduleWidth;
	min-width: $moduleWidth;
	//min-width: 100%;
	@include media-breakpoint-down(md){
		margin-right: 0;
		max-width: $moduleWidth / 1.2;
		min-width: $moduleWidth / 1.2;
		padding: $moduleCardBorderRadius/2;
		@include media-landscape{

		}
		//margin-bottom: 1rem;
	}

	max-height: $moduleHeight;
	height: 100%;
	@include media-breakpoint-down(sm){
		@include media-landscape{
			max-width: $moduleWidth / 1.5;
			min-width: $moduleWidth / 1.5;
		}
	}
	@include media-breakpoint-down(xs){
		max-width: $moduleWidth;
		min-width: $moduleWidth;
		padding: $moduleCardBorderRadius;
	}

	.module-card{
		background-image: url("../../../../../assets/images/module/pattern.svg");
		background-size: cover;
		background-position: center;
		background-repeat: no-repeat;
		font-size: 1.5em;
		line-height: 1em;
		border-radius: $moduleCardBorderRadius;
		border: 2px solid lighten($primary, 30);
		font-weight: bolder;
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		text-decoration: none;
		transition: 0.4s background-color, 0.4s color;
		position: relative;
		width: 100%;
		height: 100%;
		@include media-breakpoint-down(sm){
			@include media-landscape{
				font-size: 1em;
			}
		}

		.module-title{
			display: flex;
			flex-direction: column;
			justify-content: center;
			align-items: center;
			position: relative;
			color: $primary;

			> span{
				text-align: center;
			}
		}
	}

	&:focus,
	&:active,
	&:hover{
		.module-card{
			background-image: none;

			.module-title{
				position: absolute;
				top: $moduleCardBorderRadius;
				color: $body-color
			}

			.module-info{
				display: flex;
			}
		}
	}

	.module-info{
		display: none;
		flex-direction: column;
		border-radius: $moduleCardBorderRadius;
		font-size: 0.8rem;
		line-height: 1.5em;
		position: absolute;
		padding: $moduleCardBorderRadius;
		left: 0;
		right: 0;
		bottom: 0;

		.module-info-line{
			display: flex;
			flex-direction: row;
			justify-content: space-between;

			.module-info-name{
				width: auto;
				display: flex;
				flex-direction: column;
				> span{
					display: inline-block;
					width: 100%;
					height: 100%;
					white-space: nowrap;
				}
			}

			.module-info-separator{
				width: 100%;
				display: flex;
				flex-direction: column;
				margin: 0 4px;
				> span{
					display: inline-block;
					width: 100%;
					height: 100%;
				}
				> span:first-child{
					position: relative;
					border-bottom: 1px dotted $body-color;
					top: -4px;
				}
			}

			.module-info-value{
				width: auto;
				display: flex;
				flex-direction: column;
				> span{
					display: inline-block;
					width: 100%;
					height: 100%;
				}
			}

		}

		@include media-breakpoint-down(sm){
			padding: 0.5em;
			@include media-landscape{
				.module-info-line{
					//flex-direction: column;
					.module-info-separator{
						//display: none;
					}
					.module-info-name{
						font-size: 0.9em;
					}
					.module-info-value{
						//position: absolute;
						//right: 0;
					}
				}
			}
		}
	}
}



