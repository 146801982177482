.badges-section{
	padding: $margin 0;

	.badges-title{
		margin-bottom: 6px;
		font-weight: bold;
		color: $light;
		text-transform: uppercase;
	}

	.badges-list{
		display: flex;
		flex-direction: row;
		flex-wrap: wrap;
		margin-right: -$margin;

		> .icon{
			//background: $primary;
			padding: $margin;
			border-radius: $input-border-radius;
			font-size: 3rem;
			margin-right: $margin;
			margin-bottom: $margin;
		}
	}
}

.container-badge{
	//background: $light;
	padding: map_get($spacers,1);

	background-image: url("../../../../badge/container.svg");
	background-size: contain;
	> *{
		//background: $light;
	}
}
