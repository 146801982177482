.like-body{
	@include bodyBackground;
	background-image: url("~assets/images/bg2.png");

	.school-item{
		height: calc(100vh - #{$headerHeight});
	}
}

.school-item{
	width: 100%;
	height: 100%;
	position: relative;
	display: flex;
	justify-content: center;
	align-items: center;
	min-height: 70vh;
	$responsive: false;

	.info-bar{
		position: absolute;
		top: 3em;
		right: 0;
	}

	.school-item-element{
		width: 100%;
		height: 100%;
		border: none;
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
	}

	&.school-item-landscape-only{
		.school-item-element{
			display: none;
			@media all and(orientation: landscape){
				display: block;
				//background: red;
			}

			+ .school-item-portrait{
				display: none;
				@media all and(orientation: portrait){
					display: block;
				}
			}
		}
	}

	$itemPadding: 0.3em;
	$itemRadius: 20px;
	$distance: 0.5em;
	$itemShadow: 0px 6px 6px rgba(0, 0, 0, 0.25);
	$feedWidth: 8em;

	&.school-item-show-feedback > .school-item-feedback{
		opacity: 1;
	}

	> .school-item-feedback{
		//pointer-events: none;
		opacity: 0;
		transition: opacity 1s;
		z-index: 5;
		position: absolute;
		right: 0;
		top: 10vmin;
		bottom: 2vmin;
		font-size: 3vmin;
		//overflow-y: scroll;
		//width: auto;
		display: flex;
		flex-direction: column;
		@if $responsive{
			@include media-breakpoint-down(sm){
				@include media-landscape{
					flex-direction: row;
				}
			}
		}
		justify-content: flex-start;
		//padding: $distance;
		margin-right: 8px;
		margin-top: -$distance;
		//font-size: 3vmin;

		> .school-item-feedback-section{
			display: flex;
			justify-content: flex-end;
			line-height: 1;
			margin-top: $distance;
			//flex: 1 0;
			max-width: 100%;
			@if $responsive{
				@include media-breakpoint-down(sm){
					@include media-landscape{
						margin-right: $distance/2;
						justify-content: flex-start;
						flex-direction: column;
						max-height: unset;
					}
				}
			}

			> .school-item-text{
				display: flex;
				flex-direction: column;
				margin-right: $distance;
				//align-self: flex-end;
				flex: 0 0;
				width: 50%;
				min-width: $feedWidth;
				@if $responsive{
					@include media-breakpoint-down(sm){
						@include media-landscape{
							align-self: flex-end;
							min-width: 160px;
							margin-right: 0;
						}
					}
				}

				> *{
					height: 50%;
					display: flex;
					justify-content: center;
					align-items: center;
				}

				> .school-item-feedback-title{
					flex: 1 0;
					max-height: 50px;
					white-space: nowrap;
					background: $primary;
					padding: $itemPadding $itemPadding*2;
					color: $secondary;
					box-shadow: $itemShadow;
					//font-size: 1.25em;
					text-transform: uppercase;
					@include border-left-radius($itemRadius/2);
					@if $responsive{
						@include media-breakpoint-down(sm){
							@include media-landscape{
								margin-bottom: $distance/2;
							}
						}
					}
				}

				> .school-item-feedback-desc{
					&:empty{
						opacity: 0
					}

					flex-grow: 1;
					display: flex;
					flex-direction: column;
					justify-content: flex-start;
					align-items: flex-start;
					margin-left: $distance;
					padding: $distance/2;
					color: $primary;
					font-size: 0.7em;
					margin-top: $distance;
					box-shadow: $itemShadow;
					background: $light;
					border: 1px solid fade_out($primary, 0.9);
					@include border-bottom-radius($itemRadius/2);

					> strong{
						display: block;
						text-align: left;
						white-space: nowrap;
						margin-bottom: 0.5em;
					}

					text-align: justify;

					@if $responsive{
						@include media-breakpoint-down(sm){
							@include media-landscape{
								display: none;
							}
						}
					}
				}
			}

			> .school-item-feedback-value + .school-item-text{
				display: none;
				@if $responsive{
					@include media-breakpoint-down(sm){
						@include media-landscape{

							display: flex;
							margin: 0;
							align-self: flex-end;
							> .school-item-feedback-desc{
								margin-left: 0;
								//margin: 0;
							}
						}
					}
				}
			}

			> .school-item-feedback-value{
				flex: 0 0;
				width: $feedWidth;
				height: $feedWidth;
				min-height: $feedWidth;
				min-width: $feedWidth;
				overflow: hidden;
				background: $light;
				color: $primary;
				padding: $itemPadding;
				box-shadow: $itemShadow;
				@include border-right-radius($itemRadius);
				@include border-bottom-left-radius($itemRadius);
				border: 1px solid fade_out($primary, 0.9);
				@if $responsive{
					@include media-breakpoint-down(sm){
						@include media-landscape{
							align-self: flex-end;
						}
					}
				}
				> div{
					height: 100%;
				}
				&.school-item-feedback-value-loading > div{
					font-size: 3em !important;
					display: flex;
					justify-content: center;
					align-items: center;
				}

				&.school-item-feedback-value-icon{
					> div{
						display: flex;
						justify-content: center;
						align-items: center;
						font-size: 7em;

						small{
							$s: 1em;
							text-align: center;
							font-size: $s / 7;
						}
					}
				}

				&.school-item-feedback-value-single{
					padding: $itemPadding;
					> div{
						display: flex;
						flex-direction: column;
						justify-content: center;
						align-items: center;
						font-size: 2.5em;

						> div:first-child{
							align-self: flex-start;
						}

						> div:last-child{
							align-self: flex-end;
						}

						small{
							//margin: -$distance 0;
							font-size: 0.5em;
							line-height: 1.5;
							text-transform: uppercase;
						}
					}
				}

				&.school-item-feedback-value-double{
					padding: $itemPadding*2;

					> div{
						display: flex;
						flex-direction: column;
						justify-content: space-evenly;
						font-size: 1.5em;
						line-height: 1;

						> div{
							width: 100%;
							text-transform: uppercase;

							&:first-child{
								align-self: flex-start;
							}

							&:last-child{
								align-self: flex-end;
							}

							> :first-child{
								text-align: left;
								font-size: 0.75em;
							}

							> :last-child{
								text-align: right;
							}
						}
					}
				}
			}
		}
	}
}