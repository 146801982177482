$leftWidth: 50px;
$margin: 0.5rem;
$height: 3rem;

.levels-standalone{
	display: none;
	margin-bottom: 1em;
	@include media-landscape{
		@include media-breakpoint-down(sm){
			display: flex;
		}
	}
	flex-direction: column;
	width: 100%;
	> .level{
		flex-direction: column;
		justify-content: flex-start;
		align-items: center;
		width: auto;
		font-size: 4vw;
		padding: 0;
		> .level-right{
			display: flex;
			flex-direction: row;
			width: auto;
			> .level-item{
				padding: 0;
				content: none;
				border-radius: 100%;
				flex: 0 0;
				min-width: 2em !important;
				min-height: 2em !important;
				width: 2em;
				height: 2em;
				margin-top: $margin;
				margin-right: $margin !important;
				display: flex;
				justify-content: center;
				.level-item-index{
					font-size: 1em;
					position: relative;
				}
			}
		}

		.level-pointer-container{
			display: flex;
			flex-direction: row;
		}

		.level-space,
		.level-pointer{
			margin-top: $margin;
			margin-right: $margin;
			min-width: 2em !important;
			min-height: 2em !important;
			width: 2em;
			height: 2em;
			&:before{
				top: -$margin;
				right: -$height;
				filter:  invert(1);
				transform: rotate(90deg);
			}
		}
	}
}

.level{
	display: flex;
	flex-direction: row;
	//background-color: $white;
	padding: 0.5rem;
	align-items: flex-end;
	min-height: $height;
	position: relative;
	//@include media-breakpoint-down(sm){
	//	@include media-landscape{
	//		display: none;
	//	}
	//}
	.level-item{
		display: flex !important;
		justify-content: center;
		align-items: center;
		height: auto;
		width: 100%;
		border: none;
		box-shadow: none;
		font-size: inherit;
	}

	.level-pointer{
		position: relative;
		transition: bottom 2s ease 0s;
		bottom: 0;
		height: $height;
		width: $height;
		margin-right: $margin;
		display: flex;
		justify-content: center;
		img{
			border-radius: 100%;
			height: auto;
			width: auto;
			max-width: 100%;
			max-height: 100%;
		}
		&:before{
			display: inline-block;
			position: absolute;
			top: calc(#{-$height} - 1.5em);
			width: 16px;
			height: 65px;
			content: "";
			background-image: url("~assets/images/arrow.svg");
			background-repeat: no-repeat;
		}
	}


	> .level-right{
		width: calc(100% - #{$leftWidth});
		flex-direction: column-reverse;
		//@include media-horizontal{
		//	width: 100%;
		//	flex-direction: row;
		//}

		.level-item{
			background: $secondary;
			border-radius: 8px;
			transition: background-color 0.4s,color 0.6s;
			align-self: end;
			height: auto;
			min-height: calc(#{$height} + #{$margin});
			margin-top: $margin;
			display: flex;
			align-items: center;
			justify-content: flex-end;
			text-align: right;
			//color: $primary;
			border: 1px solid $light;
			position: relative;

			@for $i from 1 through 10{
				&:nth-child(#{$i}){
					background: darken($secondary, $i*10);
					border-color: invert(darken($secondary, $i*10),50%);
				}
			}

			> .level-item-index{
				position: absolute;
				left: 0.1em;
				top: 0.1em;
				font-size: 0.8em;
			}

			&:hover{
				background: $primary;
			}
		}
	}
}

.active-sidebar .level{
	@include media-landscape{
		@include media-breakpoint-down(sm){
			.level-right > .level-item{
				margin-right: 1rem;
				min-width: calc(#{$height} + #{$margin});
			}
		}
	}
}