@mixin hideSidebar{
	left: -100%;
	&:after{
		//content: " ";
		//position: fixed;
		//left: 0;
		//bottom: $footerHeight;
		//transition: width $time;
		//width: 0;
	}
}

#sidebar-left{
	position: fixed;
	left: 0;
	top: $headerHeight;
	overflow: hidden auto;
	height: calc(100% - #{$headerHeight});
	width: $leftSidebarWidth;
	z-index: 150;
	background: $primary;
	transition: width $time, height $time, top $time*2 ease $time, left $time*2 ease;
	$leftSidebarWidth_mobile: $leftSidebarWidth *0.7;

	&:before{
		content: " ";
		position: fixed;
		background: $primary;
	}

	&:after{
		//content: " ";
		//position: fixed;
		//left: 0;
		//bottom: $footerHeight;
		//transition: width $time;
		//width: 0;
	}

	@include media-breakpoint-down(sm){
		transition: height $time ease $time*2, width $time ease $time*3, top $time*2 ease $time, left $time*2 ease;
		width: 100%;
		height: $leftSidebarWidth_mobile;
		&:after{
			//content: " ";
			//position: fixed;
			//left: 0;
			//bottom: $footerHeight;
			//background: $light;
			//width: 100%;
			//height: 8px;
			//transition: width $time;
		}
		//background: transparent;
		top: calc(100% - #{$leftSidebarWidth_mobile} - #{$footerHeight});
		@include media-landscape{
			top: calc(100% - #{$leftSidebarWidth_mobile});
		}
		overflow: auto hidden;

		&.hidden-sidebar{
			@include hideSidebar;
		}

	}

	> .nav{
		background: $primary;
		flex-direction: column;
		width: auto;
		transition: width 0.6s;
		font-size: 3rem;
		@include media-breakpoint-down(sm){
			flex-wrap: nowrap;
			height: auto;
			width: 100%;
			flex-direction: row;
			@include media-landscape{
				justify-content: center;
			}
		}

		> *{
			padding: 0.5em;
			border-radius: 0;
			max-width: 100%;
			width: $leftSidebarWidth;
			min-width: $leftSidebarWidth;
			height: $leftSidebarWidth;
			min-height: $leftSidebarWidth;
			box-shadow: none;
			border-width: 0;
			border-bottom: $menuBorder;
			border-right: $menuBorder;
			display: flex;
			justify-content: center;
			align-items: center;
			@include media-breakpoint-down(sm){
				width: $leftSidebarWidth_mobile;
				min-width: $leftSidebarWidth_mobile;
				height: $leftSidebarWidth_mobile;
				min-height: $leftSidebarWidth_mobile;
			}
			> svg{
				height: 100%;
				width: 100%;
				color: $light;
			}

			&.active,
			&:hover{
				color: $secondary;

				> svg{
					fill: $secondary;
				}

				border-color: $secondary
			}
		}
	}
}

.show-footer #sidebar-left{
	@include media-breakpoint-down(sm){
		@include hideSidebar;
	}
}

#sidebar-left-actions{
	position: fixed;
	bottom: 0;
	width: 100%;
	left: -100%;
	z-index: 100;
	display: flex;
	justify-content: space-between;
	background: $primary;
	height: $footerHeight;
	transition: left $time*2, width $time*2;
	pointer-events: none;
	@include media-breakpoint-down(sm){
		transition: left $time*1.9 ease $time*4.8,width $time*1.9 ;
		left: 0;
		@include media-landscape{
			background: transparent;
			z-index: 160;
		}
	}

	> *{
		border-radius: 0;
		border: none;
		box-shadow: none;
		pointer-events: auto;
	}
}

.hidden-sidebar + #sidebar-left-actions{
	width: $footerHeight;
	> .btn:last-child{
		opacity: 0;
		pointer-events: none;
	}
}

.show-range #sidebar-left-actions{
	left: -100%;
}