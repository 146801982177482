@import "assets/components/new/content/item/video";
@import "assets/components/new/content/item/object";
@import "assets/components/new/content/item/question";


@mixin hideSidebarRight{
	width: calc(100% - #{$leftSidebarWidth});
	margin-right: 0;
}

@mixin hideSidebarLeft{
	width: calc(100% - #{$rightSidebarWidth});
	margin-left: 0;
}

@mixin hideSidebarBooth{
	width: 100%;
	margin-left: 0;
	margin-right: 0;
}

#main{
	//min-height: $lefSidebarMinHeight;
	//margin-left: $lefSidebarWidth;
	//border-top: $menuBorder;
	margin-left: $leftSidebarWidth;
	margin-right: $rightSidebarWidth;
	width: calc(100% - #{$leftSidebarWidth} - #{$rightSidebarWidth});
	//min-height: 50vh;
	max-height: calc(100vh - #{$headerHeight});
	&.main-profile{max-height: 100% !important;}
	//padding-top: 1rem;
	//padding-right: 1rem;
	//padding-left: 1rem;
	@include media-breakpoint-up(md){
		//margin-bottom: $footerHeight;
	}
	@include media-breakpoint-down(sm){
		@include media-landscape{
			padding: 0;
			//height: 100vh;
		}
	}

	height: auto;

	transition: width $time, height $time ease $time/2, margin $time;
	overflow-x: hidden;
	overflow-y: visible;

	@include media-breakpoint-down(md){
		@include hideSidebarRight;
	}
	@include media-breakpoint-down(sm){
		@include hideSidebarBooth;
		padding-top: 0;
		padding-right: 0;
		padding-left: 0;
		//height: 100%;
		//max-height: calc(100% - #{$leftSidebarWidth} - #{$footerHeight} - 8px);
		transition: width $time ease $time*2, height $time ease $time*2, margin $time ease $time*2;
	}
}

.hide-sidebar-left #main{
	@include hideSidebarLeft;
	@include media-breakpoint-down(sm){
		@include hideSidebarBooth;
		max-height: calc(100% - #{$footerHeight} - 1rem);
	}
}

.hide-sidebar-right #main{
	@include hideSidebarRight;
	@include media-breakpoint-down(sm){
		@include hideSidebarBooth;
	}
}

.hide-sidebar-right.hide-sidebar-left #main{
	@include hideSidebarBooth;
}

.hidden-sidebar ~ #main{
	height: 100%;
	max-height: 100% !important;
}

.active-sidebar #main{
	overflow: hidden;
}
.show-question #main{
	overflow: hidden;
	height: 100%;
	.question{
		padding-bottom: 1em;
	}
}

.show-range #main{
	//height: 100%;
	max-height: 100% !important;
	padding: 1em;
	@include media-breakpoint-down(md){
		max-height: calc(100vh - #{$headerHeight});
		padding-top: 0;
		padding-right: 0;
		padding-left: 0;
		height: auto;
		@include media-landscape{
			//background: red;
			height: unset;
			max-height: unset !important;
			//max-height: 100% !important;
		}
	}
}

.show-learning-object #main{
	padding: 0;
	height: 100%;
	max-height: 100% !important;
}
.show-learning-object.active-content  #main{
	@include hideSidebarBooth;
}