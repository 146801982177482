@import "assets/components/new/content/widget/widget_level";
@import "assets/components/new/content/widget/widget_ranking";
@import "assets/components/new/content/widget/widget_badge";

#widget-tabs{
	width: 100%;
	//max-width: $rightSidebarWidth;
	margin-bottom: 4px;
	position: relative;
	text-transform: uppercase;
	&:after{
		content: " ";
		position: absolute;
		left: 0;
		right: 0;
		bottom: -4px;
		height: 4px;
		width: 100%;
		background-size: contain;
		background-repeat: repeat-x;
		background-image: url("~assets/images/ticket/bot.svg");
	}
	.widget-selector{
		background-size: cover;
		background-repeat: no-repeat;
		background-image: url("~assets/images/ticket/top.svg");
		margin-bottom: 8px;
		position: relative;
		padding: 1rem 0.6rem 0.5rem;

		&:before{
			content: " ";
			position: absolute;
			left: 0;
			width: 100%;
			height: 8px;
			bottom: -8px;
			background-image: url("~assets/images/ticket/neck.svg");
			background-repeat: no-repeat;
			background-size: 100% 100%;
		}

		> a{
			border-bottom: 1px solid $primary;
			position: relative;
			display: flex;
			justify-content: center;
			align-items: center;
			text-align: center;
			width: 50%;

			&:first-child:not(:only-child){
				border-right: 1px solid $primary;
			}

			&:last-child:not(:only-child){
				//border-left: 1px solid $primary;
			}

			&:after{
				content: " ";
				display: block;
				position: absolute;
				border-radius: $input-border-radius;
				margin: auto;
				width: 60%;
				height: 0.5rem;
				bottom: -0.25rem;
				background: transparent;
				pointer-events: none;
				z-index: 50;
				transition: 0.2s background;
			}

			&.active:after{
				background: $primary;
			}

			&:hover:after{
				background: $black;
			}
			@include media-breakpoint-down(sm){
				@include media-landscape{
					//background: red;
					border-bottom: none;
					&:last-child{
						display: none !important;
					}
					&:first-child:after{
						background: $primary;
					}
				}
			}
		}
	}
	> .tab-content{
		background: white;
		position: relative;
		overflow-y: auto;
		padding-right: 8px;
		width: 100%;

		> .tab-pane{
			height: 100%;
			@include media-breakpoint-down(sm){
				@include media-landscape{
					&:first-child{
						display: block !important;
						opacity: 1 !important;
					}
					&:last-child{
						display: none !important;
					}
				}
			}
		}
	}
}

@include media-landscape{
	@include media-breakpoint-down(sm){
		.active-sidebar{
			#widget-tabs{
				max-width: 100%;
				height: auto;
				//min-height: 100%;
				display: flex;

				&:after{
					left: unset;
					right: -4px;
					height: 100%;
					top: 0;
					bottom: 0;
					width: 4px;
					background-repeat: repeat-y;
					background-image: url("~assets/images/ticket/bot90deg.svg");
				}

				.widget-selector{
					display: none;
					flex-direction: column;
					//writing-mode: sideways-lr;
					background-image: url("~assets/images/ticket/top90deg.svg");
					margin-bottom: 0;
					margin-right: 8px;

					&:before{
						left: unset;
						right: -8px;
						height: 100%;
						width: 8px;
						bottom: 0;
						background-image: url("~assets/images/ticket/neck90deg.svg");
					}

					> a{
						border-right: 1px solid $primary;
						border-bottom: none;
						height: 50%;
						width: 100%;

						&:after{
							width: 0.5rem;
							right: -0.25rem;
							bottom: 25%;
							height: 60%;
						}

						//&:first-child:not(:only-child){
						//	border-bottom: 1px solid $primary;
						//}
					}
				}
			}
		}
	}
}