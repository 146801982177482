.question{
	position: relative;
	height: 100%;
	> .info-bar{
		position: relative;
	}
	$borderQuestion: 1px solid $primary;
	$bgQuestion: fade_out($light, 0.3);
	$cutStyle: dashed;
	.question-header{
		font-size: 1.5em;
		padding: 1em;
		@include border-top-radius(20px);
		@include media-breakpoint-down(sm){
			border-radius: 0;
			//border-top: none;
		}
		background: $light;

		border: $borderQuestion;
		border-bottom-style: $cutStyle;
	}

	.question-alternatives{
		background: $bgQuestion;
		padding: 1em;
		border: $borderQuestion;
		border-top-style: none;
		border-bottom-style: none;
		&.question-alternatives-cut{
			border-top-style: $cutStyle;
			border-bottom-style: $cutStyle;
			margin: 0.6em 0;
		}

		.btn-question-alternative{
			text-align: left;
			transition: color 0.3s, background-color 0.3s, border 0.3s;
			font-size: 1.5em;
			margin: 1em 0;
			&:not(.active){
				@extend .btn-light;
				border: $borderQuestion;
			}
			&.active{
				@extend .btn-primary;
				&.correct{
					@extend .btn-success;
				}
				&.incorrect{
					@extend .btn-danger;
				}
			}
		}
	}

	.question-answer{
		padding: 1em;
		display: flex;
		background: $bgQuestion;
		border: $borderQuestion;
		border-top-style: $cutStyle;
		@include border-bottom-radius(20px);
		@include media-breakpoint-down(sm){
			border-radius: 0;
		}
		.btn-question-answer{
			@extend .btn-primary;
			@include border-top-radius(0);
			overflow: hidden;
		}
		.btn-secondary-question-answer{
			@extend .btn-secondary;
			@include border-top-radius(0);
			overflow: hidden;
		}
	}
}