
#container{
	height: calc(100% - #{$headerHeight});
}

.ps__rail-x,
.ps__rail-y{
	opacity: 0.5;
	z-index: 100;

	&:hover{
		opacity: 1;
	}
}
#content{
	position: relative;
	@media (max-height: 450px) {
		//padding-bottom: 5rem;
	}

	&.show-range{
		//height: auto;
		padding: 0;
	}

	//position: relative;
	//overflow: hidden;
	//display: flex;
	//flex-direction: row;
	width: 100%;
	height: 100%;
	//position: absolute;
	//overflow-y: hidden;
	//overflow-x: hidden;
	overflow: hidden;
	> .ps__rail-y{
		right: $rightSidebarWidth !important;
		.ps__thumb-y{
			background-color: $primary;
			//width: 1em;
			//height: 2em !important;
		}
	}
	@include media-breakpoint-down(md){
		> .ps__rail-y{
			right: 0 !important;
		}
	}

	//position: relative;
	&[data-env="dev"]{
		&:after{
			position: fixed;
			background: black;
			color: white;
			width: 100px;
			height: 20px;
			content: "default";
			z-index: 3500;
			text-align: center;
			bottom: 0;
			right: 0;
			opacity: 0.3;
			pointer-events: none;
			@include media-breakpoint-down(xl){
				content: "xl";
			}
			@include media-breakpoint-down(lg){
				content: "lg";
			}
			@include media-breakpoint-down(md){
				content: "md";
			}
			@include media-breakpoint-down(sm){
				content: "sm";
			}
			@include media-breakpoint-down(xs){
				content: "xs";
			}
		}
	}
}

.active-sidebar #content{
	overflow: hidden;
}

@import "assets/components/new/content/sidebar_left";
@import "assets/components/new/content/sidebar_right";
@import "assets/components/new/content/main";
@import "assets/components/new/content/main_footer";