$t-right: right $time*3 ease, width $time*3 ease;
@mixin profile{
	transition: $t-right;
	right: -$rightSidebarWidth;
	top: $headerHeight;
}

#profile-header{
	width: $rightSidebarWidth;
	height: $headerHeight;
	color: $white;
	border-left: $menuBorder;
	border-bottom: $menuBorder;
	background: $primary;
	display: flex;
	justify-content: space-between;
	flex-direction: row;
	align-items: center;
	padding: 0 1rem;
	z-index: 200;
	position: absolute;
	right: 0;
	top: 0;
	transition: $t-right;
	@include media-breakpoint-down(md){
		@include profile;
	}
	@include media-breakpoint-down(xs){
		.dropdown{
			.dropdown-menu{
				position: fixed !important;
				top: calc(#{$headerHeight*2} - 1px);
				right: 0;
				left: 0;
				transform: none !important;
				border-top-left-radius: 0;
				border-top-right-radius: 0;
				//font-size: 2rem;
				//padding: 0;
			}
		}
	}

	.profile-avatar{
		display: flex;
		justify-content: center;
		align-items: center;
		flex-wrap: nowrap;
		height: 100%;
		font-size: 1rem;
		text-transform: capitalize;
		color: inherit;
		margin-right: 0.6em;
		//overflow: hidden;

		//@include
		.dropdown-menu{

		}

		.dropdown-toggle{
			border: 1px solid fade_out($light,0.8);
			border-radius: $border-radius-lg;
			display: inline-flex;
			flex-direction: row;
			justify-content: space-between;
			//align-items: center;
			height: 100%;
			max-height: calc(100% - 0.8em);
		}

		img{
			width: 2em;
			vertical-align: bottom !important;
			border-radius: $border-radius-lg;
			align-self: center;
			//border: $menuBorder;

			+ .profile-avatar-name{
				flex: 0 0;
				text-transform: capitalize;
				margin-left: 0.5rem;
				color: $light;
				white-space: normal;
				line-height: 1;
				text-align: right;
				display: flex;
			}
		}
		.dropdown-toggle:after{
			content: none;
		}

	}
}


@include media-breakpoint-down(sm){
	:not(.showing-modules):not(.showing-payment) > #profile-header{
		display: none;
	}
}

.showing-payment{
	.profile-points-value{
		display: none !important;
	}
}

$pp-x: 0.75em;
$pp-x: $input-padding-x;
$pp-y: 0.375em;
$pp-y: $input-padding-y;
.profiler-standalone{
	margin-bottom: 1em;
	width: 100%;
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	.profile-points{
		font-size: 1.5em;
		padding: 0.3em;
		.profile-points-value{
			height: auto;
		}
		.profile-points-icon{
			background: url("~assets/images/stack.svg");
			background-repeat: no-repeat;
			background-position: bottom right;
			background-size: contain;
			width: $pp-x*2.5;
			top: 0;
			bottom: 0;

		}
	}
}
.logout-standalone{
	margin-top: auto;
	display: flex;
	justify-content: center;
	width: 100%;
	padding: 0.3em;
}
.logout-standalone,
.profiler-standalone{
	@include media-breakpoint-up(sm){
		@include media-portrait{
			//display: none;
		}
	}
	@include media-breakpoint-up(md){
		display: none;
	}
}

.profile-points{
	display: flex;
	justify-content: center;
	align-items: center;
	color: $primary;
	position: relative;
	height: 100%;

	.profile-points-value{
		background: $white;
		border-radius: $border-radius;
		padding: $pp-y $pp-x*3 $pp-y $pp-x;
		height: 2rem;
		display: flex;
		justify-content: center;
		align-items: center;
		width: 100%;
	}

	.profile-points-icon{
		position: absolute;
		align-self: flex-start;
		right: $pp-x / 2;
		top: 0.5rem;
		bottom: 0;

		> img{
			width: 100%;
			height: 100%;
		}
	}
}


.show-learning-object #profile-header{
	@include profile;
}

.active-sidebar.show-learning-object #profile-header{
	width: $rightSidebarWidth;
	right: 0;
	@include media-breakpoint-down(sm){
		width: calc(100% + #{$menuBorderSize});
	}
}

.active-sidebar #profile-header{
	//background: red;
	@include media-breakpoint-down(md){
		//width: $rightSidebarWidth;
		width: 50%;
		right: 0;
	}
	@include media-breakpoint-down(sm){
		width: calc(100% + #{$menuBorderSize});
	}
}