.btn-will-unlock,
.btn-will-lock{
	&:before{
		@extend .fa;
	}
}
.btn-will-unlock{
	color: color-yiq($danger);
	@include gradient-bg($danger);
	border-color: $danger;
	@include box-shadow($btn-box-shadow);

	&:before{
		@extend .fa-lock:before;
	}

	&:hover{
		@extend .btn-success:hover;

		&:before{
			@extend .fa-unlock:before
		}
	}

}

.btn-will-lock{
	color: color-yiq($success);
	@include gradient-bg($success);
	border-color: $success;
	@include box-shadow($btn-box-shadow);

	&:before{
		@extend .fa-unlock:before
	}

	&:hover{
		@extend .btn-danger:hover;

		&:before{
			@extend .fa-lock:before;
		}
	}
}
.payments-form{
	margin-top: 1em;
	position: relative;
	iframe{
		width: 100%;
		height: 100%;
	}
	> .card{
		@include border-top-radius(0);
		height: 100%;
		overflow: hidden;
		> .payment-section{
			height: 100%;
			overflow: auto;
		}
	}
	.card-header{
		//border-radius: 0;
	}
}
.payments{
	width: 100%;
	height: auto;
	flex-direction: row;
	.btn{
		flex: 1 1;
		//border-top: 0.3em solid $light;
		border-left:  none;
		border-top: none;
		border-bottom:  0.3em solid $light;
		border-right: 0.3em solid $light;;
		box-shadow: none !important;
		text-decoration: none;
		font-size: 20px;
		@include media-breakpoint-down(sm){
			font-size: 15px;
			padding: 0.6em;
		}
		text-transform: uppercase;
		font-weight: lighter;
		transition: border 0.6s;
		padding: 1em;
		line-height: 1;
		> :last-child{
			margin-top: 0.3em;
		}
		&:hover{
			border-bottom-color: $primary;
		}
		&.active{

			border-bottom-color: $secondary;
		}
	}
}

.card_input{
	padding-left: 68px;
	background-repeat: no-repeat;
	background-position: left center;
}
