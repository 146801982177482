#breadcrumb-header{
	height: $breadcrumbHeight;
	width: calc(100% + 2em);
	margin-top: - 1px;
	//margin-left: -1em;
	@include media-breakpoint-down(sm){
		//margin-top: -1px;
		margin-left: 0;
		margin-bottom: 0;
	}
	transition: left $time ease $time*1.6;
	@include media-breakpoint-down(sm){
		left: 0;
		transition: left $time;
		+ *{
			top: 1em
		}
	}
	border-top: $menuBorder;
	border-bottom: $menuBorder;
	background: $primary;

	+ *{
		margin-top: 1rem;
	}

	//color: $secondary;
	.breadcrumb{
		height: 100%;
		width: 100%;
		padding-top: 0;
		padding-bottom: 0;
		border-radius: 0;
		display: flex;
		align-items: center;
	}
	+ *{
		margin-top: 1em;
	}
	+ .fluid-card{
		&.absolute-card{
			> .card-header{
				position: absolute;
				width: 100%;
				background: $white;
				z-index: 6;
				opacity: 0.1;
				transition: opacity 1s;
				&:hover{
					opacity: 1;
				}
			}
		}
		//height: 100%;
		height: calc(100% - #{$headerHeight} + #{$breadcrumbHeight});
		//min-height: 100vh;
		//padding-bottom: $footerHeight;

		margin-right: -1rem;
		margin-left: -1rem;
		margin-top: -1rem;
		//margin-bottom: 0 !important;
		border-radius: 0;
		> .card-body{
			padding: 0;
		}
	}
}
