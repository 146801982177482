.full-container{

}


.loader-container{
	&,
	#root{
		background: $light;
		height: 100%;
	}
}

.resize-for-loader{
	min-height: 500px;
}

.loader{
	position: absolute;

	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	min-height: 500px;
	opacity: 1;
	transition: opacity 1s;

	&.loader-hide{
		opacity: 0;
		pointer-events: none;
	}

	&.loader-basic{
		min-height: 50px;
		background: fade_out($light, 0.5);
		z-index: 1;
	}

	&.loader-global{
		position: fixed;
		height: 100%;
		min-height: 100%;
		z-index: 5000 !important;
	}

	background: $light;
	padding: 4rem;
	@include media-breakpoint-down(sm){
		padding: 0;
		@include media-landscape{
			justify-content: flex-start;
			padding-top: 10vh;
		}
	}
	z-index: 1000;
	//position: fixed;
	.loader-content{
		display: flex;
		justify-content: center;
		align-items: center;
		flex-direction: column;
		width: 12rem;
		margin-bottom: 0.6rem;

		.loader-title{
			font-size: 1.5rem;
			text-align: center;
			text-transform: uppercase;
			color: $primary;
			background: $secondary;
			border: 1px solid $primary;
			box-shadow: $box-shadow;
			border-radius: $border-radius-lg;
			padding: 0.2rem 0.4rem;
			width: 100%;
			margin: 0.6rem 0;
		}

		.loader-logo{
			display: flex;
			justify-content: center;
			align-items: center;
			background: $primary;
			border-radius: $border-radius-lg;
			z-index: 540;
			padding: 1rem;
			box-shadow: $box-shadow;
			width: 100%;
			height: 100%;

			img{
				width: 100%;
				height: auto;
			}
		}
	}

	i{
		font-size: 6rem;
		margin-top: 0.6rem;
		color: $primary;
	}

	&.loader-card{
		.loader-content{
			background: $secondary;
			border: 2px solid $primary;
			box-shadow: $box-shadow;
			height: 18rem;
			padding: 4rem 1rem;
			position: relative;
			border-radius: $border-radius-lg;

			.loader-logo + .loader-title{
				top: unset;
				bottom: 0;
				transform: rotate(180deg);
			}

			.loader-title{
				order: 10;
				font-size: 1rem;
				position: absolute;
				top: 0;
				border: none;
				box-shadow: none;
				text-align: right;
				margin: 0;
				display: flex;
				justify-content: space-between;
				align-items: center;

				.loader-spinner{
					background: $primary;
					padding: 0.3rem;
					border-radius: $border-radius-lg;
				}

				i{
					font-size: 1rem;
					margin: 0;
					position: unset;
					color: $secondary;
				}
			}

			.loader-logo{
				width: calc(100% - 3rem);
				height: 100%;
				background: $primary;
			}
		}
	}
}