

@include media-breakpoint-down(sm){
	table.table tr {
		display: flex;
		flex-direction: column;
		align-items: center;
		th:first-child{
			width: 100%;
			text-align: center;
		}
		td{
			min-width: 100% !important;
			&.action-cell{
				.btn{
					width: 100%;
				}
			}
		}
	}
}

table.table{
	td,th{
		vertical-align: middle;
	}
	.avatar-cell{
		width: 5em;
		height: 5em;
		text-align: center;
		> img{
			background: $light;
			max-height: 100%;
			max-width: 100%;
		}
	}
}