#brand-header{
	padding: 0;
	> .navbar-brand{
		width: $leftSidebarWidth;
		height: $headerHeight;
		flex: 1 0;

		display: flex;
		justify-content: center;
		align-items: center;
		> img{
			max-width: 100%;
			max-height: 100%;
			height: 100%;
			width: auto;
		}
	}
	> .nav-link{
		//@include media-breakpoint-down(xs){
		//	width: 100%;
		//	padding: 0;
		//	overflow: hidden;
		//}
		> img{
			max-width: 100%;
			max-height: 100%;
			height: auto;
			width: 100%;
		}
		&:first-child{
			margin-left: 2rem;
		}
		text-align: left;
		display: flex;
		align-items: center;
	}
	> *{
		height: 100%;
		padding: map_get($spacers,2);
	}
}

:not(.login-header):not(.showing-payment) > #brand-header{
	> .navbar-brand{
		@include media-breakpoint-down(sm){
			//margin-right: $spacer/2;
			//width: $leftSidebarWidth/2
		}
		@include media-breakpoint-down(sm){
			width: 100%;
			@include media-landscape{
				width: 0;
				padding: 0;
				overflow: hidden;
			}
		}
	}
	> .nav-link{
		@include media-breakpoint-down(sm){
			width: 100%;
			@include media-portrait{
				width: 0;
				padding: 0;
				overflow: hidden;
			}
			//font-size: 0.5em;
		}
	}
}

.showing-payment{
	> #brand-header{
		> .navbar-brand{
			@include media-breakpoint-down(sm){
				//margin-right: $spacer/2;
				//width: $leftSidebarWidth/2
			}
			@include media-breakpoint-down(sm){
				width: 100%;
				@include media-portrait{
					width: 0;
					padding: 0;
					overflow: hidden;
				}
			}
		}

		> .nav-link{
			@include media-breakpoint-down(sm){
				width: 100%;

				@include media-landscape{
					width: 0;
					padding: 0;
					overflow: hidden;
				}
				//font-size: 0.5em;
			}
		}
	}
}