

.swal2-shown #root{
	filter: blur(8px) opacity(0.6);
}
.swal2-container{
	padding-bottom: 0.625em;
}
.school-popup.swal2-popup{
	$ratio: 326/305;
	$size: 50%;
	$alertAvatarSize: 40%;
	$alertHeaderSize: $size*0.2;
	background-image: linear-gradient(to bottom, $primary 0 50%, $secondary 50% 100%);
	background-repeat: repeat-x;
	min-height: 60vh;
	max-height: 100%;
	padding-bottom: 0;
	@include media-breakpoint-down(sm){
		min-width: 100%;
		min-height: 100%;
	}
	//border-radius: 5%;

	.swal2-header{
		position: absolute;
		left: 0;
		top: 0;
		right: 0;
		height: $alertHeaderSize;
		display: flex;
		justify-content: center;
		z-index: 10;
		.swal2-title{
			color: $light;
		}
		.swal2-close{
			top: $alertHeaderSize/10;
			right: $alertHeaderSize/10;
			border: none !important;
			box-shadow: none !important;
			outline: none !important;
			* {
				border: none !important;
				box-shadow: none !important;
				outline: none !important;
			}
		}
	}
	.swal2-content{
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		width: 100%;
		height: 100%;
		z-index: 5;
		padding: 0;
		.swal2-html-container{
			color: $light;
			align-self: center;
			font-size: 1.5rem;
			font-weight: bold;
			height: 100%;
			display: flex !important;
			justify-content: space-between;
			.message-avatar{
				width: $alertAvatarSize;
				height: 100%;
				flex: 1 1;
				z-index: 100;
				position: absolute;
				left: 0;
				right: 0;
				bottom: 0;
				top: 0;
				background-repeat: no-repeat;
				background-position: bottom;
				background-size: contain;
				@include media-breakpoint-down(xs){
					display: none;
				}
				> div{
					position: sticky;
					bottom: 0;
				}

				img{
					max-height: 100%;
					max-width: 100%;
				}
			}
			.messages{
				height: 100%;
				flex: 1 1;
				z-index: 5;
				display: flex !important;
				flex-direction: column;
				justify-content: space-between;
				text-align: left;
				position: relative;
				overflow-y: auto;

				background-position: bottom;
				background-repeat: no-repeat;
				background-size: contain;
				//@include media-breakpoint-down(sm){
				//	position: absolute;
				//	right: 0;
				//	bottom: 0;
				//	width: 60%;
				//	&:only-child{
				//		width: 100%;
				//	}
				//}
				&.without-avatar{
					justify-content: center;
					text-align: center;
					.message-top,
					.message-bottom{
						padding-left: $alertHeaderSize/4;
						justify-content: center;
					}
				}
				.message-top,
				.message-bottom{
					display: inline-flex;
					width: 100%;
					padding-left: $alertAvatarSize;
					@include media-breakpoint-down(xs){
						padding-left: $alertHeaderSize/4;
						justify-content: center;
						margin-bottom: $alertHeaderSize/4;
					}
					padding-bottom: $alertHeaderSize;
					padding-top: $alertHeaderSize;
					padding-right: $alertHeaderSize/4;
					height: 50%;
					z-index: 10;
					flex: 1 1;
				}
				.message-top{
					padding-bottom: $alertHeaderSize/5;
					align-items: flex-end;
					position: sticky;
					top: 0;
					z-index: 15;
					background: $primary;
				}
				.message-bottom{
					padding-top: $alertHeaderSize/5;
					align-items: flex-start;
					color: $primary;
					position: relative;
					background: $secondary;
				}
			}
		}
	}
	.swal2-footer{
		$alertFooterHeight: 2em;
		position: absolute;
		left: $alertAvatarSize;
		right: 0;
		bottom: 0;
		margin: 0;
		padding: 0;
		height: $alertFooterHeight;
		border: none;
		text-transform: uppercase;
		z-index: 10;
		text-align: left;
		background: $secondary;


		.footer{
			width: 100%;
			padding-bottom: 0.3em;
			filter: invert(1);
			.footer-image{
				width: $alertFooterHeight;
				background-position: center bottom;
				background-size: contain;
			}
			.footer-logo-text{
				width: calc(100% - #{$alertFooterHeight});
				background-position: left bottom;
				background-size: contain;
			}
			.footer-image,
			.footer-logo-text{
				height: 100%;
				display: inline-block;
				background-repeat: no-repeat;
			}
		}
	}
	.swal2-actions{
		position: absolute;
		bottom: 0;
		width: auto;
		z-index: 10;
	}
}