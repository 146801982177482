@mixin selector{
	top: 0.25em;
	width: auto;
	height: auto;
	transition: top $time ease-in $time;
}

#mobile-selector{
	position: absolute;
	top: -$headerHeight;
	right: 0;
	width: 0;
	height: 0;
	transition: top 0.6s ease-out;
	margin-left: auto;
	padding: 0;
	box-shadow: none;
	border: none;
	background: transparent;

	i{
		font-size: 2rem;
		color: $secondary;
	}

	&:hover i{
		color: darken($secondary, 20%)
	}

	@include media-breakpoint-down(md){
		@include selector;
	}
}

.show-learning-object #mobile-selector{
	@include selector;
}