@import "assets/variables";

$distance: 0.6rem;
.files{
	color: $primary;

	.file-separator{
		margin: $distance 0;
		height: 1px;
		width: 100%;
		background: $primary;
		position: relative;

		&:after{
			content: " ";
			width: 3em;
			height: $distance;

			position: absolute;
			left: 1em;
			top: -$distance/2;
			border-radius: $distance;

			background: $primary;
		}
	}

	.file-entries{
		display: flex;
		flex-flow: row wrap;
		margin-right: -$distance;
		margin-left: -$distance;
		padding: $distance 0;

		> .file-entry{
			background: fade_out($white, 0.5);
			padding: $distance;
			border-radius: $distance;
			border: 1px solid $primary;
			text-decoration: none;
			font-size: 3rem;
			min-width: calc(1em + #{$distance} * 2);
			max-width: calc(1em + #{$distance} * 2);
			display: flex;
			justify-content: center;
			align-items: center;
			flex: 1 0;
			margin-right: $distance;
			margin-bottom: $distance;
			margin-left: $distance;
		}
	}
}