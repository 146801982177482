$distance: 0.2em;
$size: 2.3em;
$sizeRow: 13*($size + $distance);
$closeSize: $size*2/3;
$tableSize: $sizeRow + $closeSize*2;

.range{
	//font-size: 0.7em;
	position: relative;
	background: fade_out($light,0.5);
	display: flex;
	flex-direction: column;
	@include media-breakpoint-down(lg){
		//justify-content: flex-end;
	}
	border-radius: $border-radius;
	//height: 100%;
	.close-button{
		font-size: $distance*5;
		line-height: 1;
		height: $closeSize;
		width: $closeSize;
		padding: 0;
	}
	@include media-breakpoint-down(md){
		padding-right: 2em;
		//justify-content: center;
	}
	@include media-breakpoint-down(sm){
		height: auto;
		font-size: 2.9vw;
		padding-right: 0;
	}
	.range-title + .range-row{
		margin-top: $distance;
	}
	> .range-table-list{
		height: 100%;
		padding-top: 1em;
		padding-left: 1em;
		flex-direction: column;
		width: auto;
		margin: 0;
		flex-shrink: 0;
		@media (max-width: calc(#{$sizeRow} - #{$leftSidebarWidth} - #{$rightSidebarWidth})){
			//background: red;
		}
		@include media-breakpoint-down(lg){
			display: none;
		}
		.range-table{
			width: $tableSize;
			margin-right: 0;
		}
	}
	> .range-table{
		width: 100%;
		height: 100%;
		align-items: center;
		justify-content: flex-start;
		//padding-left: ;
		+ .range-legend{
			margin-top: 1em;
			margin-bottom:  1em;
			width: auto;
		}
	}
}

@include media-breakpoint-up(sm){
	#main > .range{
		font-size: 100%;
	}
}

.range-table-list{
	//font-size: 0.5em;
	display: flex;
	flex-wrap: wrap;

	position: relative;
	overflow: hidden;
	width: 100%;
	margin-right: -1em;
	margin-bottom: -1em;
	//padding-left: 1em;

	.range-table{
		flex-grow: 1;
		margin-bottom: 1em;
		margin-right: 1em;
		border: 1px solid fade_out($primary, 0.5);
		padding-bottom: 1em;
		//max-width: calc(#{$sizeRow} + 2rem);
	}

}

.show-range #main-footer {
	//display: none;
	//.range-table-list{
	//	padding-bottom: 8px;
	//	display: none;
	//	//background: red;
	//	@include media-breakpoint-down(lg){
	//		display: flex;
	//	}
	//}
}

$emphasis: (
		0: lighten($secondary,20%),
		1: lighten($primary,40%),
		2: lighten($primary,60%),
		3: lighten($primary,80%)
);
$emphasis-extended: (
		0: lighten($secondary,20%),
		1: lighten($primary,20%),
		2: lighten($primary,30%),
		3: lighten($primary,40%),
		4: lighten($primary,50%),
		5: lighten($primary,60%),
		6: lighten($primary,70%),
		7: lighten($primary,80%)
);

.range-legend{
	width: 100%;
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	margin: auto;
	margin-top: 1em;
	.bg-emphasis{
		padding: 0.6em;
		display: inline-block;
		border: 1px solid transparent;
		border-radius: 100%;
		margin-right: 0.3em;
	}
	> *{
		display: flex;
		align-items: center;
		margin-top: 0.3em;
	}
	@each $value,$color in $emphasis{
		.bg-emphasis-#{$value}{
			color: color-yiq($color);
			@include gradient-bg($color);
			border-color: darken($color,30%);
			@include box-shadow($btn-box-shadow);
		}
	}
	&.range-extended{
		@each $value,$color in $emphasis-extended{
			.bg-emphasis-#{$value}{
				color: color-yiq($color);
				@include gradient-bg($color);
				border-color: darken($color,30%);
				@include box-shadow($btn-box-shadow);
			}
		}
	}
}

.range-table{
	font-size: 1em;

	&.range-table-sm{
		font-size: $btn-font-size-sm/2
	}

	padding: $closeSize $closeSize $closeSize;
	@include media-portrait(){
		@include media-breakpoint-down(sm){
			padding: $distance;
		}
	}
	user-select: none;
	overflow: hidden;
	display: inline-flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;
	width: $sizeRow;
	//height: $sizeRow;
	+ .range-legend{
		width: $sizeRow;
		max-height: $sizeRow;
	}
	&.range-table-action{
		cursor: pointer;
		position: relative;
		background-color: fade_out($light,0.6);
		transition: background-color 0.5s;
		&.range-table-action-active{
			background: fade_out($dark,0.6);
		}
		border-radius: $border-radius;
	}
	.range-title{
		font-size: 1.5em;
		text-align: center;
		width: auto;
	}

	.range-row{
		display: flex;
		flex-direction: row;
		justify-content: center;
		align-items: center;
		width: $sizeRow;
		height: $size;
		&:not(:last-child){
			margin-bottom: $distance;
		}

		.range-cell{
			display: flex;
			flex-direction: row;
			justify-content: center;
			align-items: center;
			margin-right: $distance;
			&:last-child{
				margin-right: 0;
			}
			width: $size;
			height: $size;
			min-width: $size;
			min-height: $size;
			border-radius: $border-radius;

			background: lighten($secondary, 20%);
			border: 1px solid $secondary;


			@each $value,$color in $emphasis{
				&.btn-range-cell-emphasis-#{$value}{
					padding: 0;
					@include button-variant($color,darken($color,30%))
				}
				&.btn[data-range-cell-emphasis="#{$value}"]{
					padding: 0;
					@include button-variant($color,darken($color,30%))
				}
				&.range-cell-emphasis-#{$value}{
					padding: 0;
					color: color-yiq($color);
					@include gradient-bg($color);
					border-color: darken($color,30%);
					@include box-shadow($btn-box-shadow);
				}
			}
			&.range-cell-extended{
				@each $value,$color in $emphasis-extended{
					&.btn-range-cell-emphasis-#{$value}{
						padding: 0;
						@include button-variant($color,darken($color,30%))
					}
					&.btn[data-range-cell-emphasis="#{$value}"]{
						padding: 0;
						@include button-variant($color,darken($color,30%))
					}
					&.range-cell-emphasis-#{$value}{
						padding: 0;
						color: color-yiq($color);
						@include gradient-bg($color);
						border-color: darken($color,30%);
						@include box-shadow($btn-box-shadow);
					}
				}
			}
		}
	}
}