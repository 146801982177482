$margin: 0.5rem;
$height: 3rem;

@keyframes glow{
	from{
		@include box-shadow(0 0 0 $input-btn-focus-width rgba($warning, .25),inset 0 3px 5px rgba($black, .125));
	}
	to{
		@include box-shadow(0 0 0 $input-btn-focus-width rgba($warning, .80),inset 0 3px 5px rgba($black, .125));
	}
}

.ranking{
	display: flex;
	flex-direction: column;
	padding: 2*$margin $margin 0 $margin;
	min-height: $height;

	> .ranking-item{
		display: flex;
		flex-direction: row;
		margin-bottom: $margin;

		&.ranking-item-me{
			.ranking-progress svg,
			.ranking-avatar img{
				border: 1px solid fade_out($primary,0.5);
				animation: glow 1s ease-in-out infinite alternate;
				@include box-shadow($btn-focus-box-shadow, $btn-active-box-shadow);
			}
		}
		.ranking-avatar{
			width: $height;
			height: $height;
			display: flex;
			justify-content: center;
			align-items: center;
			margin: 0 $margin;

			img{
				//padding: $margin/2;
				border-radius: 100%;
				height: auto;
				width: auto;
				max-width: 100%;
				max-height: 100%;
			}
		}

		.ranking-progress{
			height: $height;
			width: 100%;
			display: flex;
			justify-content: center;
			align-items: center;
			padding: $margin 0;

			svg{
				width: 100%;
				height: 100%;
				max-width: 100%;
				max-height: 100%;
			}
		}
	}
}