#level-header{
	display: flex;
	justify-content: flex-end;
	align-items: center;
	height: 100%;
	width: auto;
	padding: 0 1em;
	transition: width $time,right $time;
	position: absolute;
	right: $rightSidebarWidth;
	margin-left: auto;
	@include media-breakpoint-down($breakpointSchool){
		right: 3em;
	}
	@include media-breakpoint-down(sm){
		top: -100%;
		&.level-header-fixed{
			right: 0;
		}
	}

	@for $i from 2 through 10{
		> :nth-child(#{$i}){
			background: darken($secondary, $i*10);
			border-color: invert(darken($secondary, $i*10),50%);
		}
	}

	> *{
		font-size: 0.8em;
		@include media-breakpoint-down(sm){
			//font-size: 0.6em;
		}
		width: 2em;
		height: 2em;
		padding: 0;
		margin-left: 0.5rem;
		border-radius: 100%;
		display: flex;
		justify-content: center;
		align-items: center;
	}
}

.show-learning-object #level-header{
	right: 3rem;
}