$zindex: 150;
#header{
	height: $headerHeight;
	padding-left: 0;
	display: flex;
	justify-content: space-between;
	background: $primary;
	position: relative;
	border-bottom: $menuBorder;
	z-index: 181;
}


@import "assets/components/new/header/header_level";
@import "assets/components/new/header/header_brand";
@import "assets/components/new/header/header_profile";
@import "assets/components/new/header/mobile_selector";
@import "assets/components/new/header/breadcrumb";


#navbar-extra{
	display: flex;
	position: relative;
	right: 0;
	width: $rightSidebarWidth;
	transition: right $time;
	@include media-breakpoint-down($breakpointSchool){
		display: none;
		right: -$rightSidebarWidth;
	}
}

#school-subheader{
	z-index: 500;

	display: flex;

	&.simple-header{
		align-items: center;
		padding: 0 1em;

		.nav-item{
			display: flex;
			justify-content: center;
			align-items: center;

			.nav-link.active{
				color: color-yiq($secondary);
				&:not(.badge){
					background-color: darken($secondary, 10%);
				}
			}
		}
	}

	> .nav-item > .badge{
		width: 1.2rem;
		height: 1.2rem;
		font-size: 0.8rem;
		display: flex;
		justify-content: center;
		align-items: center;
	}

	> .nav-item + .nav-item{
		margin-left: 0.3em;
	}

	#header > & + *{
		margin-top: 0;
		margin-bottom: 0;
	}

	+ *{
		margin-top: 3em;
		margin-bottom: 1em;
	}
}